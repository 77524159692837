import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';


import { AuthGuard } from './shared/guard/auth.guard';
import { AcessoGuard } from './shared/guard/acesso.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserLogadoService } from './shared/user-logado/user-logado.service';
import { FirstLetterPipe } from './shared/pipes/first-letter.pipe';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { LocalStorageModule } from 'angular-2-local-storage';
import { PhonePipeModule } from './shared/pipes/phone-pipe-module';

//registerLocaleData(localePt, 'pt-BR', localePtExtra);
registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    FirstLetterPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    LocalStorageModule.forRoot({
      prefix: 'my-app',
      storageType: 'localStorage'
    }),
    ToastrModule.forRoot(),
    PhonePipeModule.forRoot()
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthGuard,
    AcessoGuard,
    UserLogadoService,
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
