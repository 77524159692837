import { NgModule }      from '@angular/core';
import { PhonePipe } from './phone-pipe';

 @NgModule({
     imports:        [],
     declarations:   [PhonePipe],
     exports:        [PhonePipe],
 })

 export class PhonePipeModule {

   static forRoot() {
      return {
          ngModule: PhonePipeModule,
          providers: [],
      };
   }
 } 