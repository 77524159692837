import { Injectable } from '@angular/core';

import { AppSettings } from './../../app.settings';
import { UserLogado } from './userLogado';

import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class UserLogadoService {

  user: UserLogado = new UserLogado();

  constructor(
    private _localStorage: LocalStorageService
  ) {
    this.carregarDadosUserLogado();
  }

  carregarDadosUserLogado() {

    let user = JSON.parse(localStorage.getItem('currentUserGestor'));

    if (user) {
      this.user.token = user.token;
      this.user.nome = user.nome;
      this.user.cargo = user.cargo;
      this.user.id = user.idusuario;
      if (user.foto){
        this.user.foto = user.foto;
      } else {
        this.user.foto = AppSettings.NO_PHOTO_BASE64;
      }
    }
  }

  validarAcesso(tag){
    let filtrado = this.user.cargo.tags.filter(function(obj){ 
      return obj == tag; 
    });
    if (filtrado.length > 0){
      return true;
    }
    return false;
  }

}
